import {Injectable}  from '@angular/core';
import {BaseService} from "../../shared/services/base.service";
import {environment} from "../../../environments/environment";
import {ToastService} from "../../shared/services/toast.service";

@Injectable({
    providedIn: 'root'
})
export class StorageService extends BaseService
{
    public prefix: string = environment.storagePrefix;

    public constructor(
        public override toastService: ToastService,
    )
    {
        super(toastService);
    }

    public write(key: string, data: any): void
    {
        localStorage.setItem(`${this.prefix}_${key}`, JSON.stringify(data));
    }

    public read(key: string): any
    {
        const data: string | null = localStorage.getItem(`${this.prefix}_${key}`);
        return data ? JSON.parse(data) : null;
    }

    public remove(key: string): void
    {
        localStorage.removeItem(`${this.prefix}_${key}`)
    }
}
