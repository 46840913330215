import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseService} from "./base.service";
import {User} from "../../models/user.model";
import {StorageService} from "../../core/services/storage.service";
import {ToastService} from "./toast.service";

@Injectable({
    providedIn: 'root'
})
export class UsersService extends BaseService {
    public initials: string = this.user?.name?.match(/(^\S\S?|\s\S)?/g).map((v: string) => v.trim()).join('').match(/(^\S|\S$)?/g).join('').toLocaleUpperCase();

    constructor(
        public override toastService: ToastService,
        private http: HttpClient,
        private storageService: StorageService
    ) {
        super(toastService);
    }

    /**
     * @deprecated The method should not be used, please use the AuthService
     */
    public get user(): any {
        return this.storageService.read('user') ?? null;
    }

    /**
     * @deprecated The method should not be used, please use the AuthService
    */
    public set user(user: User | null) {
        this.storageService.write('user', user);
    }

    public list(pageNumber: number, pageSize: number = 50, array = null): Observable<any> {
        const url: string = array
            ? `${this.apiUrl}/users?array`
            : `${this.apiUrl}/users?page[number]=${pageNumber}&page[size]=${pageSize}`;

        return this.http.get(url).pipe();
    }

    public invite(data: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/users/invite`, data).pipe();
    }

    public update(data: any, id: any): Observable<any> {
        return this.http.put(`${this.apiUrl}/users/${id}`, data).pipe();
    }

    public delete(id: any): Observable<any> {
        return this.http.delete(`${this.apiUrl}/users/${id}`).pipe();
    }

    public resendUserInvite(data: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/users/invite/resend`, data).pipe();
    }

    public ErrorHandler(response: any): void {
        console.error(response);
    }
}
