import {Injectable} from '@angular/core';
import {BaseService} from '../../shared/services/base.service';
import {StorageService} from "./storage.service";
import {ToastService} from "../../shared/services/toast.service";

@Injectable({
    providedIn: 'root'
})
export class PermissionsService extends BaseService {
    constructor(
        public override toastService: ToastService,
        private storageService: StorageService,
    ) {
        super(toastService);
    }

    public get roles(): string[] {
        return this.storageService.read('roles');
    }

    public set roles(roles: string[]) {
        this.storageService.write('roles', roles);
    }

    public get permissions(): string[] {
        return this.storageService.read('permissions');
    }

    public set permissions(permissions: string[]) {
        this.storageService.write('roles', permissions);
    }

    public can(permission: string) {
        return !!this.permissions.find(p => p === permission) || this.hasRole('super-admin');
    }

    public cant(permission: string) {
        return !this.permissions.find(p => p === permission);
    }

    public hasRole(role: string) {
        return this.roles.find(p => p === role);
    }
}
