import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable, of, tap, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {StorageService} from './storage.service';
import {BaseService} from "../../shared/services/base.service";
import {UsersService} from "../../shared/services/users.service";
import {PermissionsService} from "./permissions.service";
import {environment} from "../../../environments/environment";
import {User} from "../../models/user.model";
import {ToastService} from "../../shared/services/toast.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseService {
    public initials: string = this.user?.name?.match(/(^\S\S?|\s\S)?/g).map((v: string) => v.trim()).join('').match(/(^\S|\S$)?/g).join('').toLocaleUpperCase();

    constructor(
        public override toastService: ToastService,
        public jwtHelper: JwtHelperService,
        private httpClient: HttpClient,
        private usersService: UsersService,
        private permissionsService: PermissionsService,
        private storageService: StorageService
    ) {
        super(toastService);
    }

    public get accessToken(): string {
        return this.storageService.read('accessToken') ?? '';
    }

    public set accessToken(token: string) {
        this.storageService.write('accessToken', token);
    }

    public get tenant(): any {
        return this.storageService.read('tenant');
    }

    public set tenant(tenant: any) {
        this.storageService.write('tenant', tenant);
    }

    public get user(): any {
        return this.storageService.read('user') ?? null;
    }

    public set user(user: User|null) {
        this.storageService.write('user', user);
    }

    public get integrations() {
        return this.storageService.read('tenant').integrations;
    }

    public get accountIntegration() {
        return this.integrations.account;
    }

    public isAuthenticated(): boolean {
        return this.accessToken !== '' && !this.jwtHelper.isTokenExpired(this.accessToken);
    }

    public canRefreshToken(): boolean {
        return this.jwtHelper.isTokenExpired(this.accessToken);
    }

    public login(credentials: { email: string, password: string }): Observable<any> {
        if (this.isAuthenticated()) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${this.apiUrl}/login`, credentials).pipe(
            switchMap((response: any) => {
                this.authenticateUser(response);
                return of(response);
            })
        );
    }

    public forgotPassword(email: string): Observable<any> {
        if (this.isAuthenticated()) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${this.apiUrl}/forgot-password`, email).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public createPassword(url: string, passwordData: any): Observable<any> {
        if (this.isAuthenticated()) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${url}`, passwordData).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public resetPassword(credentials: {
        token: string,
        password: string,
        password_confirmation: string
    }): Observable<any> {
        if (this.isAuthenticated()) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${this.apiUrl}/reset-password`, credentials).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public verifyEmail(url: string): Observable<any> {
        return this.httpClient.get(url).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public resendVerificationEmail(email: string): Observable<any> {
        if (this.isAuthenticated()) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${this.apiUrl}/email/verify/${email}/resend`, {frontend_url: `${environment.localPath}/email-verified`}).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public logout(): void {
        this.deauthenticateUser();
        this.router.navigate(['login']);
    }

    public loginWithToken(): Observable<any>
    {
        console.log('%c Refreshing token...', 'color: orange;');

        return this.httpClient.post(`${this.apiUrl}/refresh/${this.user.id}`, {
            token: this.accessToken,
            tenant_id: this.tenant.id
        }, {
            headers: {
                skip: 'true',
                Authorization: `Bearer ${this.accessToken}`,
                'X-Tenant': `${this.tenant.id}`
            }
        });
    }

    public refresh(): Observable<any> {
        console.log('%c Refreshing token...', 'color: orange;');

        return this.loginWithToken().pipe(
            tap((response) => {
                this.authenticateUser(response);
                return true;
            }),
            catchError((error) => {
                this.deauthenticateUser();
                this.router.navigate(['login']);

                throw new error(error);
            })
        );
    }

    public check(): boolean {
        if (this.isAuthenticated()) {
            console.log('check - authed');
            return true;
        }

        if (!this.accessToken || this.accessToken == '') {
            console.log('check - no token');
            return false;
        }

        if (this.jwtHelper.isTokenExpired(this.accessToken)) {
            console.log('check - token expired');
            return false;
        }

        console.log('check - none of the above');
        return false
    }

    public tenantHasAddresses(): Observable<boolean> {
        if (this.tenant?.billing_address && this.tenant.trading_address) {
            return of(true);
        } else {
            return of(false);
        }
    }

    public tenantHasActiveAccount(): boolean {
        const accountIntegration = this.tenant.integrations.account;

        if (!accountIntegration) {
            return false;
        }

        return accountIntegration.active && accountIntegration.last_connected_at !== null;
    }

    public tenantHasLogo(): boolean {
        return this.tenant.logo_url;
    }

    public register(registerForm: any): Observable<any> {
        return this.httpClient.post(`${this.apiUrl}/register`, registerForm).pipe();
    }

    public authenticateUser(response: any): void {
        console.log('%c Setting the user as authenticated...', 'color: pink;', response);

        this.tenant = response.data.primary_tenant;
        this.user = response.data?.user;
        this.accessToken = response.data.token;

        this.permissionsService.roles = response.data.user.roles;
        this.permissionsService.permissions = response.data.user.permissions;

        // setUser({
        //   id: this.usersService.user.id,
        //   username: this.usersService.user.name,
        //   email: this.usersService.user.email
        // });
    }

    public deauthenticateUser() {
        this.user = null;

        this.storageService.remove('accessToken');
        this.storageService.remove('tenant');
        this.storageService.remove('roles');
        this.storageService.remove('permissions');

        // setUser(null);
    }
}
